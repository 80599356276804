@font-face {
    font-family: 'Gill Sans';
    src: url(../fonts/SFUGillSansRegular.eot) format("embedded-opentype"),
         url(../fonts/SFUGillSansRegular.woff) format("woff"),
         url(../fonts/SFUGillSansRegular.otf) format("opentype");
         font-weight: 400;
         font-style: normal;
  }

@font-face {
    font-family: 'Gill Sans';
    src: url(../fonts/GillSans-SemiBold.eot) format("embedded-opentype"),
        url(../fonts/GillSans-SemiBold.woff) format("woff"),
        url(../fonts/GillSans-SemiBold.otf) format("opentype");
        font-weight: 500;
        font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(../fonts/GillSansLight.eot) format("embedded-opentype"),
        url(../fonts/GillSansLight.woff) format("woff"),
        url(../fonts/GillSansLight.otf) format("opentype");
        font-weight: 300;
        font-weight: normal;
}

@import url('https://fonts.googleapis.com/css?family=Cinzel:400,700');

html, body {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    letter-spacing: .135px;
    line-height: 1.360;
    
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

a, a:visited {
    color: $nav-color;
    // display: inline-block;
    text-decoration: none;
}

.table-of-contents, .about-the-book {
    a.active {
        color: #222;
    }
}

.about-the-authors {
    a, a:visited, a:hover, a:focus {
        color: white;
    }
    line-height: 1.55;
}

.white {
    color: white;
}

.light {
    font-weight: 300;
}

.monk-title {
    position: relative;
    top: 220px;
    text-transform: uppercase;
    display: inline-block;
    margin: 0; padding: 0;
    font-size: 42px;
    line-height: 1.25;
    font-family: 'Cinzel', serif;
    left: 440px;
    text-transform: uppercase;
}

#about-the-authors {
    font-family: 'Cinzel', serif;
    color: $nav-color;
    font-weight: 300;
    font-size: 24px;
}

.title-sub {
    color: $nav-color;
    display: block;
    top: 200px;
    position: relative;
    left: 440px;
    max-width: 600px;
}

.caption-text {
    position: relative;
    top: 220px;
    line-height: 1.5;
    color: $nav-color;
    left: 440px;

    + .caption-text {
        margin-top: 30px;
    }
}

body.table-of-contents {
    ul {
        padding-left: 60px;
    }
}

.img-caption {
    color: white;
}

ul {
    list-style-type: none;
}

li > p {
    padding: 0; margin: 0 !important;
}


sub {
    font-weight: 500;
    left: 2px;
    bottom: 3px;
}

blockquote {
    margin: 0 0 0 40px;
    position: relative;
}

blockquote {
     p { 
        padding: 0; margin: 0;
        margin-top: -12px;
    }
  }

.footnote {
    position: absolute;
    bottom: 28px;
    left: -7px;
}

em {
    font-weight: 300;
}