@import 'normalize';
@import 'vars';
@import 'type';
@import 'nav';


body {
    width: 1200px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    @extend %clearfix;
}

.inner {
    position: relative;
    @extend %clearfix;
}

.about-the-book {
    .text-content {
        width: 650px;
        left: -25px;
        padding-top: 40px;
        position: relative;
        float: right;
    }
}

.about-the-authors {
    .text-content {
        width: 600px;
        height: 100vh;
        position: relative;
    }
}

.text-content > h3 {
    font-family: 'Cinzel', serif;
    color: $nav-color;
    font-weight: 300;
    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.text-content > h5 {
    font-size: 12px;
}

.title-wrap {
    width: 480px;
    position: relative;
    @extend %clearfix;
    left: 80px;
    font-size: 16px;
}

.note-wrap {
    position: relative;
}

.the-monk {
    background-color: $background;
    @extend %clearfix;
    .img-content {
        background: $background;
        background-image: url('../img/monk_03.jpg');
        background-repeat: no-repeat;
        height: 1018px;
        float: left;
        // left: 0;
        // bottom: 365px;
        margin-top: -325px;
        width: 671px;
        z-index: -1;
        max-height: 800px;
        background-size: 65%;
        // background-position-y: -365px;
    }
    .img-caption {
        color: $nav-color;
        position: relative;
        left: -360px;
        bottom: -335px;
    }

    .inner {
        @extend %clearfix;
    }

}

.table-of-contents {
    background-repeat: no-repeat;
    
    .img-caption {
        position: absolute;
        bottom: 20px;
        right: 260px;
        color: $nav-color;
        width: 180px;
    }

    .img-content {
        position: relative;
        background-image: url('../img/monk_xray.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 726px;
        height: 888px;
        float: right;
        bottom: 20px;
        background-size: 92%;
        z-index: -1;
        margin-right: -125px;
    }

    .text-content {
        left: 80px;
        max-width: 600px;
        position: relative;
        font-size: 12px !important;
        float: left;
        @extend %clearfix;
        ul {
            margin-top: -10px;
            max-width: 600px;
            padding-left: 55px;
            @extend %clearfix;
        }
        li {
            margin-bottom: 5px;
        }
    }
}

.about-the-book {
    // margin-bottom: 40px;
    .img-caption {
        position: relative;
        display: block;
        color: $nav-color;
        max-width: 180px;
        left: 100px;
        top: 0;
        // white-space: pre-line;
    }

    .img-content {
        background-image: url('../img/monk_back.png');
        background-repeat: no-repeat;
        position: relative;
        height: 870px;
        width: 319px;
        left: 70px;
        // top: 0;
        background-size: contain;
        // float: left;
    }
}


.about-the-authors {
    background: $background;
    p {
        color: white;
    }
    .img-caption {
        position: absolute;
        color: #919B9B;
        right: 290px;
        bottom: 130px;
    }

    .img-content {
        background: url('../img/monk_06.jpg');
        background-repeat: no-repeat;
        height: 100%;
        float: right;
        right: 0;
        width: 455px;
        height: 800px;
        z-index: -1;
        background-position-x: right;
        background-size: 400px auto;
    }

    .text-content {
        position: relative;
        padding: 0 0 0 40px;
        height: 100%;
        width: 675px;
        float: left;
        p:last-child {
            margin-bottom: 20px;
        }
    }
}

.js-credits {
  font-size: 12px;
  letter-spacing: 1px;
  position: absolute;
  bottom: -55px;
  left: 80px;
  display: block;
  z-index: 2;
}

.credit-modal {
    font-size: 12px !important;
    letter-spacing: 1px;
    bottom: -70px;
    left: 160px;
    color: $nav-color;
}


.visible {
    display: block;
    position: absolute;
    }
.hide {
    display: none;
}
body.about-the-book {
    a.js-credits {
        display: none !important;
}
}
body.about-the-authors {
    a.js-credits {
        display: none !important;
}
}
body.the-monk {
    a.js-credits {
        display: none !important;
}
}