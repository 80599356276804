.sidebar-nav {
    position: relative;
    z-index: 2;
    left: 520px;
    width: 760px;
    font-weight: 500;
    letter-spacing: .25px;
    padding-top: 60px;
    ul {
        list-style-type: none;
        list-style: none;
        padding: 0; margin: 0;

        li {
            display: inline-block;
            text-transform: uppercase;
            list-style-type: none;
            list-style: none;
            padding: 0; margin: 0;
            padding-right: 40px;

            a:hover {
                color: darken($nav-color, 20%);
            }
    
            a, a:visited, a:active {
                text-decoration: none;
                padding-right: 0 20px;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

// .the-monk .sidebar-nav {
//     left: 440px;
// }